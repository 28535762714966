import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import clsx from "clsx"
import { Link, graphql } from "gatsby"
import YouTube, { YouTubeProps } from "react-youtube"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import Layout from "../components/Layout"
import OnScreen from "../components/OnScreen"
import SEO from "../components/SEO"
// @ts-ignore
import happyMeter from "../images/happy-meter.gif"
import StartButton from "../components/StartButton"

const FiveCores: FunctionComponent = ({ data }) => {
  const newData = data.allContentful5Cores.edges[0].node
  const [ready, setReady] = useState(false)

  const [rocketShipFlying, setRocketShipFlying] = useState(false)

  const textRef = useRef<HTMLDivElement>(null)
  const rocketOffset = textRef.current?.getBoundingClientRect().top || -1

  useEffect(() => {
    setTimeout(() => setRocketShipFlying(true), 300)
    setTimeout(() => setRocketShipFlying(false), 2000)
    setTimeout(() => setReady(true), 300)
  }, [])

  function getRocketOffset() {
    if (typeof window !== "undefined") {
      if (window.innerWidth <= 640) {
        return 24
      }

      if (window.innerWidth <= 768) {
        return 64
      }
    }

    return rocketOffset
  }

  const onPlayerReady: YouTubeProps["onReady"] = event => {
    // access to player in all event handlers via event.target
    // event.target.Mute()
    // event.target.setVolume(100);
    // event.target.playVideo();
  }
  const onEnd: YouTubeProps["onEnd"] = event => {
    // access to player in all event handlers via event.target
    event.target.playVideo()
  }

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 1,
      mute: 1,
      rel: 0,
      fs: 0,
      showinfo: 0,
    },
  }
  return (
    <Layout absoluteHeader>
      <SEO
        description="Discover the 5 core Areas of Life fueling your life and learn the critical role each plays in your ability to break earth's gravitational pull for personal development."
        title="5 Core Areas of Life"
        link="https://mooremomentum.com/5-core-areas-of-life/"
      />

      <div className="primary-gradient-bg -mb-48 pb-64 py-32 md:pt-40 lg:-mb-16 lg:pb-48 lg:pt-36">
        <div className="container mx-auto px-4 relative text-white z-10">
          <div className="mt-8 lg:mt-0">
            <div className="text-center">
              <h1 className="font-Orbitron mb-4 text-shadow text-3xl md:text-4xl lg:text-5xl lg:leading-tight">
                {newData.headline}
              </h1>
            </div>

            <div className="gap-8 grid grid-cols-1 items-center mt-8 lg:mt-8">
              <div className="flex max-w-xl mock-shadow mx-auto slide-in-left w-full sm:order-2 2xl:max-w-3xl">
                <svg
                  className="h-44 w-auto sm:h-64 md:h-64 2xl:h-80"
                  width="198"
                  height="518"
                  viewBox="0 0 198 518"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M190.5 15V7.5H183H115C55.6294 7.5 7.5 55.6294 7.5 115V403C7.5 462.371 55.6294 510.5 115 510.5H183H190.5V503V15Z"
                    fill="#55BEE0"
                    stroke="black"
                    strokeWidth="15"
                  />
                  <circle
                    className="animate-pulse"
                    cx="116.5"
                    cy="137.5"
                    r="39.5"
                    fill="black"
                  />
                  <circle cx="112" cy="240" r="19" fill="black" />
                  <circle cx="77" cy="273" r="19" fill="black" />
                  <circle cx="147" cy="273" r="19" fill="black" />
                  <circle cx="112" cy="309" r="19" fill="black" />
                  <rect
                    x="125"
                    y="359"
                    width="31"
                    height="31"
                    rx="3"
                    fill="black"
                  />
                </svg>

                <div className="bg-black flex-1 py-4 relative">
                  <div className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 h-full">
                    {ready && (
                      <YouTube
                        videoId="1yf8nmzbeRk"
                        className="video-iframe h-full"
                        opts={opts}
                        onReady={onPlayerReady}
                        onEnd={onEnd}
                      />
                    )}
                    {/* <div className="wistia_responsive_padding relative iframe-wrapper">
                      <div className="wistia_responsive_wrapper h-full left-0 absolute top-0 w-full">
                        <div className="wistia_embed wistia_async_ppgmrzjgt5 videoFoam=true autoPlay=true h-full relative w-full">
                          <div className="wistia_swatch h-full left-0 opacity-1 overflow-hidden absolute top-0 transition-opacity width-full">
                            <img
                              src="https://fast.wistia.com/embed/medias/ppgmrzjgt5/swatch"
                              className="filter blur-sm h-full object-contain width-full"
                              alt=""
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                <svg
                  className="h-44 w-auto sm:h-64 md:h-64 2xl:h-80"
                  width="198"
                  height="518"
                  viewBox="0 0 198 518"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 15V7.5H15H83C142.371 7.5 190.5 55.6294 190.5 115V403C190.5 462.371 142.371 510.5 83 510.5H15H7.5V503V15Z"
                    fill="#E3675B"
                    stroke="black"
                    strokeWidth="15"
                  />
                  <circle
                    className="animate-pulse"
                    cx="83.5"
                    cy="288.5"
                    r="39.5"
                    fill="black"
                  />
                  <circle cx="83" cy="117" r="19" fill="black" />
                  <circle cx="48" cy="150" r="19" fill="black" />
                  <circle cx="118" cy="150" r="19" fill="black" />
                  <circle cx="83" cy="186" r="19" fill="black" />
                  <rect
                    x="36"
                    y="359"
                    width="31"
                    height="31"
                    rx="3"
                    fill="black"
                  />
                </svg>
              </div>

              <div
                className="max-w-xl mx-auto text-center sm:order-1 xl:max-w-3xl"
                ref={textRef}
              >
                <div className="space-y-4 text-link">
                  {renderRichText(newData.subText, {
                    renderMark: {
                      [MARKS.BOLD]: text => (
                        <span className="font-bold">{text}</span>
                      ),
                      [MARKS.ITALIC]: text => <em>{text}</em>,
                    },
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                      [BLOCKS.HEADING_1]: (node, children) => (
                        <h1 className="font-Orbitron text-4xl">{children}</h1>
                      ),
                      [BLOCKS.HEADING_2]: (node, children) => (
                        <h2 className="font-Orbitron text-3xl">{children}</h2>
                      ),
                      [BLOCKS.HEADING_3]: (node, children) => (
                        <h3 className="font-Orbitron text-xl">{children}</h3>
                      ),
                      [BLOCKS.EMBEDDED_ASSET]: node => {
                        if (!node.data.target) {
                          return
                        }
                        // console.log("Imagess",node.data.target.gatsbyImageData)
                        const { gatsbyImageData } = node.data.target
                        if (!gatsbyImageData) {
                          // asset is not an image
                          return null
                        }
                        return (
                          <figure className="flex flex-wrap justify-center items-center">
                            <GatsbyImage
                              image={gatsbyImageData}
                              alt="Blog Image"
                            />
                          </figure>
                        )
                      },
                    },
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={clsx(
            "absolute right-6 slide-in-bottom slide-in-bottom--slow w-28 sm:w-32 lg:right-12 xl:w-44 2xl:w-48",
            rocketShipFlying ? "z-10" : "z-0"
          )}
          style={{ top: getRocketOffset() }}
        >
          <StaticImage
            alt="Rocketship"
            placeholder="blurred"
            src="../images/rocket.webp"
          />
        </div>
      </div>

      <div className="overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 space-y-16">
          <div className="gap-8 grid grid-cols-1 items-center lg:gap-4 lg:grid-cols-2">
            <OnScreen
              className="mx-auto p-4 relative w-2/3 sm:max-w-xs lg:max-w-full"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-mindset-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-8">
                <StaticImage
                  alt="will moore 5 cores mindset"
                  placeholder="blurred"
                  src="../images/mindset-icon.png"
                />
              </div>
            </OnScreen>

            <OnScreen
              className="text-center lg:text-left"
              classToAdd="slide-in-right"
            >
              <div className="max-w-xl mb-8 mx-auto space-y-4 lg:max-w-full text-link">
                {renderRichText(newData.firstSection, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </OnScreen>
          </div>

          <div className="gap-8 grid grid-cols-1 items-center lg:gap-4 lg:grid-cols-2">
            <OnScreen
              className="mx-auto p-4 relative w-2/3 sm:max-w-xs lg:max-w-full lg:order-2"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-finance-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-8">
                <StaticImage
                  alt="will moore 5 cores career finance"
                  placeholder="blurred"
                  src="../images/finance-icon.png"
                />
              </div>
            </OnScreen>

            <OnScreen
              className="text-center lg:order-1 lg:text-left"
              classToAdd="slide-in-left"
            >
              <div className="max-w-xl mb-8 mx-auto space-y-4 lg:max-w-full text-link">
                {renderRichText(newData.secondSection, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </OnScreen>
          </div>

          <div className="gap-8 grid grid-cols-1 items-center lg:gap-4 lg:grid-cols-2">
            <OnScreen
              className="mx-auto p-4 relative w-2/3 sm:max-w-xs lg:max-w-full"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-relationship-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-8">
                <StaticImage
                  alt="will moore 5 cores relationship"
                  placeholder="blurred"
                  src="../images/relationship-icon.png"
                />
              </div>
            </OnScreen>

            <OnScreen
              className="text-center lg:text-left"
              classToAdd="slide-in-right"
            >
              <div className="max-w-xl mb-8 mx-auto space-y-4 lg:max-w-full text-link">
                {renderRichText(newData.thirdSection, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </OnScreen>
          </div>

          <div className="gap-8 grid grid-cols-1 items-center lg:gap-4 lg:grid-cols-2">
            <OnScreen
              className="mx-auto p-4 relative w-2/3 sm:max-w-xs lg:max-w-full lg:order-2"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-physical-health-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-8">
                <StaticImage
                  alt="will moore 5 cores physical health"
                  placeholder="blurred"
                  src="../images/physical-health-icon.png"
                />
              </div>
            </OnScreen>

            <OnScreen
              className="text-center lg:order-1 lg:text-left"
              classToAdd="slide-in-left"
            >
              <div className="max-w-xl mb-8 mx-auto space-y-4 lg:max-w-full text-link">
                {renderRichText(newData.fourthSection, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </OnScreen>
          </div>

          <div className="gap-8 grid grid-cols-1 items-center lg:gap-4 lg:grid-cols-2">
            <OnScreen
              className="mx-auto p-4 relative w-2/3 sm:max-w-xs lg:max-w-full"
              classToAdd="scale-in-center"
            >
              <div className="absolute core-mental-health-gradient inset-0 rounded-full shadow-lg spin" />

              <div className="core-gradient relative rounded-full shadow-lg p-8">
                <StaticImage
                  alt="will moore 5 cores emotional health"
                  placeholder="blurred"
                  src="../images/emotional-health-icon.png"
                />
              </div>
            </OnScreen>

            <OnScreen
              className="text-center lg:text-left"
              classToAdd="slide-in-right"
            >
              <div className="max-w-xl mb-8 mx-auto space-y-4 lg:max-w-full text-link">
                {renderRichText(newData.fifthSection, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>
            </OnScreen>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4">
        <div className="flex flex-wrap items-end my-16 sm:mt-32 xl:mt-48">
          <div className="bg-white max-w-lg mb-12 mx-4 relative shadow-lg lg:mx-0">
            <div className="absolute -left-12 spin spin--slow -top-12 -z-1 xl:-left-24 xl:-top-24">
              <svg
                className="system-svg"
                width="401"
                height="401"
                viewBox="0 0 401 401"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="200.5"
                  cy="200.5"
                  r="188"
                  stroke="#FF4242"
                  strokeWidth="25"
                  strokeDasharray="6 23"
                />
              </svg>
            </div>

            <div className="max-w-sm">
              <img alt="a broken system" src={happyMeter} />
            </div>
          </div>

          <div className="bg-gray -mt-32 -mx-4 pb-12 pt-36 rounded-md lg:-ml-24 lg:mt-0 lg:py-12 lg:flex-1">
            <div className="mx-auto px-8 lg:w-2/3">
              <h2 className="font-Orbitron relative text-3xl text-underline lg:text-4xl">
                Begin Your Journey
              </h2>

              <div className="mb-8 space-y-4 text-link">
                {renderRichText(newData.mission, {
                  renderMark: {
                    [MARKS.BOLD]: text => (
                      <span className="font-bold">{text}</span>
                    ),
                    [MARKS.ITALIC]: text => <em>{text}</em>,
                  },
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                    [BLOCKS.HEADING_1]: (node, children) => (
                      <h1 className="font-Orbitron text-4xl">{children}</h1>
                    ),
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <h2 className="font-Orbitron text-3xl">{children}</h2>
                    ),
                    [BLOCKS.HEADING_3]: (node, children) => (
                      <h3 className="font-Orbitron text-xl">{children}</h3>
                    ),
                    [BLOCKS.EMBEDDED_ASSET]: node => {
                      if (!node.data.target) {
                        return
                      }
                      // console.log("Imagess",node.data.target.gatsbyImageData)
                      const { gatsbyImageData } = node.data.target
                      if (!gatsbyImageData) {
                        // asset is not an image
                        return null
                      }
                      return (
                        <figure className="flex flex-wrap justify-center items-center">
                          <GatsbyImage
                            image={gatsbyImageData}
                            alt="Blog Image"
                          />
                        </figure>
                      )
                    },
                  },
                })}
              </div>

              <StartButton className="py-3 px-12 bg-coral rounded-full text-sm text-white" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const data = graphql`
  query FiveCores {
    allContentful5Cores {
      edges {
        node {
          headline
          subText {
            raw
          }
          firstSection {
            raw
          }

          secondSection {
            raw
          }
          thirdSection {
            raw
          }
          fourthSection {
            raw
          }
          fifthSection {
            raw
          }
          mission {
            raw
          }
        }
      }
    }
  }
`

export default FiveCores
